import type { Category } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
export default (
  categories: Pick<Category, 'id' | 'parent'>[],
  customCategoryFallbackIcons: Nullable<Record<string, string>>
) => {
  if (!categories || !customCategoryFallbackIcons) {
    return null;
  }

  // First try to find a category with a parent
  const categoryWithParent = categories?.find(
    (cat) => cat?.id && cat.parent && cat.id in customCategoryFallbackIcons!
  );

  if (categoryWithParent?.id) {
    return customCategoryFallbackIcons![categoryWithParent.id];
  }

  // Fallback to any matching category
  const category = categories?.find(
    (cat) => cat?.id && cat.id in customCategoryFallbackIcons!
  );

  return category?.id ? customCategoryFallbackIcons![category.id] : null;
};
